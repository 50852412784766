import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../components/common/customMedia'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'
import { Image } from '../components/common/Image'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" keywords={[]} />
    <Container>
    </Container>
  </Layout>
)

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

export default NotFoundPage